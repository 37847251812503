const setToLocalStorage = (value=[]) => {
    if (typeof(window.localStorage) !== "undefined") {
        window.localStorage.setItem('frag-sonja-merkliste', JSON.stringify(value))
    }
}
const getFromLocalStorage = async () => {
    if (typeof (window.localStorage) !== "undefined") {
        try {
            return await JSON.parse(window.localStorage.getItem('frag-sonja-merkliste') || "[]");
        }catch (e){
            //do nothing
        }
    }
    return Promise.resolve([])
}

class Merkliste {
    elements = []
    _onStoreChange = () => {
        this.onStoreChange(this)
        setToLocalStorage(this.elements)
    }
    onStoreChange = () => {
        console.log(this.elements)
    }
    constructor(onStoreChange) {
        if (onStoreChange) {
            this.onStoreChange = onStoreChange;
        }
        getFromLocalStorage().then(
          (v) => {
              this.elements = v
              console.log(v)
              onStoreChange(this)
          }
        ).catch(
          () => {
              this.elements = []
          }
        )
    }

    add(element) {
        this.elements = [
          ...this.elements,
            {...element.dataset, count: 1}
        ].sort((a, b)=> {
            const x = a.productName.toLowerCase();
            const y = b.productName.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        })
        this._onStoreChange();
    }

    hasElement(element) {
        return this.elements.find((item) => {
            const compare = {...element.dataset};
            return item.productId === `${compare.productId}`;
        });
    }

    removeElement(element) {
        this.elements = this.elements.filter((item) => {
            const compare = {...element.dataset};
            return item.productId !== `${compare.productId}`;
        });
        this._onStoreChange();
    }

    getCount(element) {
        return this.elements.find((item) => {
            const compare = {...element.dataset};
            return item.productId === `${compare.counterId}`;
        }) ||{count: 1}
    }

    countUp(productId) {
        this.elements = this.elements.map((item) => {
            if (item.productId === `${productId}`) {
                item.count = parseInt(item.count)+1
            }
            return item
        });
        this._onStoreChange();
    }

    countDown(productId) {
        this.elements = this.elements.map((item) => {
            if (item.productId === `${productId}`) {
                const count = parseInt(item.count) - 1
                item.count = (count <= 0) ? 1 : count
            }
            return item
        });
        this._onStoreChange();

    }
}

const handleCount = (store, direction, productId) => (e) => {
    if (direction === 'up') {
        store.countUp(productId);
    } else {
        store.countDown(productId);
    }
}

const createEntryTemplate = ({productName, count, productId}) => {
    const placeholder = document.createElement('div');
    placeholder.innerHTML = `<div id="anfrageliste-${productId}" data-product-id="${productId}" class="frag-sonja-merkliste__entry">
                        <p>${productName}</p>
                        <div class="frag-sonja-merkliste__counter">
                            <span class="counter-down" data-product-id="${productId}"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="1" y1="10" x2="19" y2="10" stroke="white" stroke-width="2" stroke-linecap="round"></line></svg></span>
                            <span data-counter-id="${productId}">${count||1}</span>
                            <span class="counter-up" data-product-id="${productId}"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="10" x2="19" y2="10" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <line x1="10" y1="19" x2="10" y2="1" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </svg></span>
                        </div>
                    </div>`;
    const node = placeholder.firstElementChild;

    return node;
};

const createCountEventListeners = (element, store, productId) => {
    const upElement = element.querySelector('.counter-up');
    const downElement = element.querySelector('.counter-down');
    upElement.addEventListener("click", handleCount(store, 'up', productId))
    downElement.addEventListener("click", handleCount(store, 'down', productId))
    return () => {
        upElement.removeEventListener("click", handleCount(store, 'up'))
        downElement.removeEventListener("click", handleCount(store, 'up'))
    }

}

const allProductBtns = () => document.querySelectorAll('.btn[data-product-id]');
const allProductHeart = () => document.querySelectorAll('.product__heart[data-product-id]');

const buildMailToString = ({ subject, body }) =>
  `mailto:info@frag-sonja.de?Subject=${encodeURIComponent(subject)}&Body=${encodeURIComponent(
    body
  )}`;

const createEmailLink = (elements=[]) => {
    const row = (elem) => `${elem.count} x ${elem.productName.replace(/<\/?[^>]+(>|$)/g, "").replace(/(\r\n|\n|\r)/gm, "").replace(/\s\s+/g, ' ')}`
    const bodyWithElements = `Sehr geehrte Frau Papadopoulou,

über ein Angebot zu folgenden Artikeln würde ich mich sehr freuen.

${elements.map(row).join("\n")}


[Ihre nachricht an mich]

Mit freundlichen Grüßen

`;
    const bodyWithoutElements = `Sehr geehrte Frau Papadopoulou,

[Ihre nachricht an mich]

Mit freundlichen Grüßen


`;
    const body = elements.length > 0 ? bodyWithElements : bodyWithoutElements;
    const subject = "Produkt- und Beratungsanfrage";
    document.querySelector('#mailLink').setAttribute('href', buildMailToString({subject, body}))
}

const addSlider = (slider)=> {
  let isDown = false;
  let startX;
  let scrollLeft;
  slider.addEventListener('mousedown', (e) => {
    console.log(e)
    isDown = true;
    slider.classList.add('catalogue__content--active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('catalogue__content--active');
  });
  slider.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });

}


function init() {
    createEmailLink();

    const reRender = (store) => {
        allProductBtns().forEach(element => {
            element.innerHTML = !store.hasElement(element) ? 'auf Merkliste setzen' : 'von Merkliste entfernen'
        })
        allProductHeart().forEach(element => {
            if (store.hasElement(element)) {
                element.classList.add('product__heart--selected');
            }else {
                element.classList.remove('product__heart--selected');

            }

        })
        document.querySelector("#heart-text").innerHTML = store.elements.length;
        document.querySelectorAll("span[data-counter-id]").forEach((element) => {
            element.innerHTML = store.getCount(element).count
        });


        document.querySelectorAll(`[id^='anfrageliste-']`).forEach(element => {
            element.remove()
        })


        store.elements.forEach(entry => {
            if (!document.querySelector(`#anfrageliste-${entry.productId}`)) {
                const node = createEntryTemplate(entry)
                createCountEventListeners(node, store, `${entry.productId}`);
                document.querySelector('#anfrageliste').appendChild(node);
            }
        })

        if (store.elements.length > 0) {
            document.querySelector("#frag-sonja-anfrageliste-empty").classList.add('hide');
        }else{
            document.querySelector("#frag-sonja-anfrageliste-empty").classList.remove('hide');
        }
        createEmailLink(store.elements)
    }

    const store = new Merkliste(reRender)

    const handleHeartClick = (element) => (e) => {
        element.classList.toggle('product__heart--selected');
        if (element.classList.contains('product__heart--selected')) {
            store.add(element)
            console.log("store add", store.elements);
        } else {
            store.removeElement(element);
            console.log("store remove", store.elements);
        }

    }

    document.querySelectorAll(".product").forEach(element => {

        const heart = element.querySelector(".product__heart");
        const btn = element.querySelector(".btn");

        if (heart) {
            heart.addEventListener("click", handleHeartClick(heart))
        }
        if (btn) {
            btn.addEventListener("click", handleHeartClick(heart))
        }



    })
    document.querySelectorAll('.catalogue__content').forEach(addSlider);


    let x = document.querySelectorAll(".product__price");
    for (let i = 0, len = x.length; i < len; i++) {
        let num = Number(x[i].innerHTML)
          .toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});

        x[i].innerHTML = num;
    }

}

init();

