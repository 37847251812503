/* eslint-disable */
(function (window, document) {
    // Google Analytics Function for embedding tracking code
    // Google Analytics tracking ID
    const $tracking_id = '';

    // Google Analytics Cookie Domain & Path (needed for clearing cookies – have look in the inspector to get the values needed)
    const $tracking_cookie_domain = '.frag-sonja.com';
    const $tracking_cookie_path = '/';

    function embedTrackingCode() {
        // add <script> to head
        const gascript = document.createElement('script');
        gascript.async = true;
        gascript.src = `https://www.googletagmanager.com/gtag/js?id=${$tracking_id}`;
        document
            .getElementsByTagName('head')[0]
            .appendChild(gascript, document.getElementsByTagName('head')[0]);

        // track pageview
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', $tracking_id, { anonymize_ip: true });

        console.log('Google Analytics Tracking enabled');
    }

    function deleteGACookies() {
        // because the gtag cookie uses the tracking id with "-" replaced though "_"
        // we have to do this string manipulation too
        const $gtag_cookie = `_gat_gtag_${$tracking_id.replace(/-/g, '_')}`;

        clearCookie('_ga', $tracking_cookie_domain, $tracking_cookie_path);
        clearCookie('_gid', $tracking_cookie_domain, $tracking_cookie_path);
        clearCookie('_gat', $tracking_cookie_domain, $tracking_cookie_path);
        clearCookie($gtag_cookie, $tracking_cookie_domain, $tracking_cookie_path);
        location.reload();

        console.log('Google Analytics Tracking disabled');
    }

    // Insites Cookie Consent with Opt-IN for MATOMO tracking Cookie
    // Source: https://cookieconsent.insites.com/documentation/disabling-cookies/
    window.addEventListener('load', () => {
        window.cookieconsent.initialise({
            cookie: {
                expiryDays: 1
            },
            type: 'opt-in',
            content: {
                message:
                    'Wir verwenden auf dieser Website Cookies. Wenn Sie Cookies akzeptieren, helfen Sie uns, die Benutzerfreundlichkeit auf dieser Website zu verbessern.',
                allow: 'Akzeptieren',
                deny: 'Keine Cookies setzen',
                link: 'Weitere Informationen in unserer Datenschutzerklärung',
                href: '/datenschutz',
                policy: 'Cookie Einstellungen'
            },
            onPopupOpen() {
                document.body.classList.add('cookieconsent-banner-opened');
            },
            onPopupClose() {
                document.body.classList.remove('cookieconsent-banner-opened');
            },
            onInitialise(status) {
                const { type } = this.options;
                const didConsent = this.hasConsented();
                if (type == 'opt-in' && didConsent) {
                    // enable cookies
                    embedTrackingCode();
                }
                if (type == 'opt-out' && !didConsent) {
                    // disable cookies
                }
            },
            onStatusChange(status, chosenBefore) {
                const { type } = this.options;
                const didConsent = this.hasConsented();
                if (type == 'opt-in' && didConsent) {
                    // enable cookies
                    embedTrackingCode();
                }
                if (type == 'opt-in' && !didConsent) {
                    // disable cookies
                    deleteGACookies();
                }
                if (type == 'opt-out' && !didConsent) {
                    // disable cookies
                    deleteGACookies();
                }
            },
            onRevokeChoice() {
                const { type } = this.options;
                if (type == 'opt-in') {
                    // disable cookies
                }
                if (type == 'opt-out') {
                    // enable cookies
                    embedTrackingCode();
                }
            }
        });
    });

    // Function for deleting Cookies (such as that ones from Google Analytics)
    // Source: https://blog.tcs.de/delete-clear-google-analytics-cookies-with-javascript/
    function clearCookie(d, b, c) {
        try {
            if (
                (function (h) {
                    const e = document.cookie.split(';');
                    let a = '';
                    let f = '';
                    let g = '';
                    for (i = 0; i < e.length; i++) {
                        a = e[i].split('=');
                        f = a[0].replace(/^\s+|\s+$/g, '');
                        if (f == h) {
                            if (a.length > 1) g = unescape(a[1].replace(/^\s+|\s+$/g, ''));
                            return g;
                        }
                    }
                    return null;
                })(d)
            ) {
                b = b || document.domain;
                c = c || '/';
                document.cookie = `${d}=; expires=${new Date()}; domain=${b}; path=${c}`;
            }
        } catch (j) {}
    }

    // function for triggering a click on the cc-revoke button
    // wich will show the consent banner again.
    // You may use it in a link, such as this example:
    // <a href="#" onclick="openCCbanner(); return false;">Cookie Consent</a>
    function openCCbanner() {
        const el = document.querySelector('.cc-revoke');
        el.click();
    }

    // ---- OPTIONAL -------------------
    // Google Analytics Opt-Out Cookie
    const $tracking_disable_cookie = `ga-disable-${$tracking_id}`;
    if (document.cookie.indexOf(`${$tracking_disable_cookie}=true`) > -1) {
        window[$tracking_disable_cookie] = true;
    }

    function gaOptout() {
        document.cookie = `${$tracking_disable_cookie}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
        window[$tracking_disable_cookie] = true;
        alert('Der Opt-Out-Cookie für das Deaktivieren von Google Analytics wurde abgelegt.');
    }
})(window, document);
