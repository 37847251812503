const form = document.getElementById('contactform');

document.addEventListener('submit', (event) => {
    event.preventDefault();

    fetch(form.action, {
        method: 'POST',
        body: new FormData(event.target)
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then((data) => {
            if (data.message) {
                const response = document.getElementById('contactform--response');
                response.innerHTML = data.message;
                if (data.code === 200) {
                    form.remove();
                }
            }
        })
        .catch((error) => {
            console.warn(error);
        });
});
