const body = document.body;
const heroImg = document.getElementById('frag-sonja');
const topnav = document.getElementById('topnav');

const toggleNavHide = (isIntersecting) => {
    const hasClass = topnav.classList.contains('navbar--hide');
    switch (true) {
        case !isIntersecting && hasClass: {
            topnav.classList.remove('navbar--hide');
            break;
        }
        case isIntersecting && !hasClass: {
            topnav.classList.add('navbar--hide');
            break;
        }
        default: {
            //do nothing
        }
    }
};

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => toggleNavHide(entry.isIntersecting));
    },
    { threshold: 1 }
);

observer.observe(heroImg);
