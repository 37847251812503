const myButton = document.getElementById('scrollToTop');
const footer = document.getElementById('footer');

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
    scrollFunction();
};

function scrollFunction() {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        myButton.style.display = 'flex';
    } else {
        myButton.style.display = 'none';
    }
    if (isInViewport(footer)) {
        myButton.style.display = 'none';
    }
}

// When the user clicks on the button, scroll to the top of the document
/**
function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
*/

var isInViewport = function (elem) {
    const bounding = elem.getBoundingClientRect();
    const height = bounding.bottom - bounding.top;

    return (
        bounding.top >= 0 &&
        bounding.bottom <=
            (window.innerHeight + height || document.documentElement.clientHeight + height)
    );
};
